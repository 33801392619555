<template>
	<v-app>
		<v-main>
			<dwtStaking />
		</v-main>
	</v-app>
</template>

<script>
import dwtStaking from './components/dwtStaking';

export default {
  name: 'App',

  components: {
    dwtStaking,
  },

  data: () => ({
    //
  }),
};
</script>