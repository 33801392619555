<template>
	<div class="dwtBody">
		<b-container class="stakingDisplay dwtNav">
			<b-row align-h="center">
				<b-col>
					<a href="https://divewallet.net"><b-img src="@/assets/logo.png" height="40px"></b-img></a>
					<span class="dwtHeader"><strong>Dive</strong>Wallet - Staking</span>
				</b-col>
			</b-row>
		</b-container>
		<div class="txAlertBox">
			<b-alert :show="txAlertStatus" dismissable :variant="txAlertVariant" @dismissed="txAlertStatus=0" @dismiss-count-down="txCountChange" class="dwtAlert"><span class="userDataLabel">{{txAlertMessage}}</span></b-alert>
		</div>
		<div class="loadingWindow" ref="loadWindow">
			<LoadSpinner :color="loadingColor" :height="loadingHeight" :width="loadingWidth"></LoadSpinner>
		</div>
		<b-container class="stakingDisplay" ref="stakeDisplay">
			<b-row align-h="center">
				<b-col>
					<w3m-button class="dwtCards" />
					<b-card class="dwtCards">
						<div class="cardHeader">Staking Pool Information</div>
						<div class="infoSection"><span class="userDataLabel">APR:</span> <span class="userDataInfo"><span v-if="userNFT == 0" class="arrow right"></span>{{formatNumber(stakingAPR)}}%</span></div>
						<div class="infoSection"><span class="userDataLabel">NFT APR:</span> <span class="userDataInfo"><span v-if="userNFT > 0" class="arrow right"></span>{{formatNumber(stakingAPRnft)}}%</span></div>
						<div class="infoSection"><span class="userDataLabel">Total Staked:</span> <span class="userDataInfo">{{formatNumber(stakingTotalTokens)}}</span></div>
						<div class="priceSection"><span class="priceInfo">(${{formatNumber(totalStakedPrice)}})</span></div>
						<div class="infoSection"><span class="userDataLabel">Deposit Fee:</span> <span class="userDataInfo">0.00%</span></div>
						<div class="infoSection"><span class="userDataLabel">Withdraw Fee:</span> <span class="userDataInfo">0.00%</span></div>
					</b-card>
					<b-card class="dwtCards">
						<div class="cardHeader">User Information</div>
						<div class="infoSection"><span class="userDataLabel">Total Tokens:</span> <span class="userDataInfo">{{formatNumber(userWalletDWT)}}</span></div>
						<div class="priceSection"><span class="priceInfo">(${{formatNumber(walletPrice)}})</span></div>
						<div class="infoSection"><span class="userDataLabel">Available to Stake:</span> <span class="userDataInfo">{{formatNumber(userUnstaked)}}</span></div>
						<div class="priceSection"><span class="priceInfo">(${{formatNumber(unstakedPrice)}})</span></div>
						<div class="infoSection"><span class="userDataLabel">Staked Tokens:</span> <span class="userDataInfo">{{formatNumber(userStaked)}}</span></div>
						<div class="priceSection"><span class="priceInfo">(${{formatNumber(stakedPrice)}})</span></div>
						<div class="sliderWindow" ref="slideWindow">
							Tokens to <span ref="sliderActionText">{{sliderAction}}</span>: {{formatNumber(sliderTokens)}}
							<v-slider
								v-model="sliderVal"
								max="100"
								min="0"
								@change="updateSliderTokens"></v-slider>
							<b-button pill size="sm" variant="outline-primary" class="pctPills dwtButton" @click="updateSliderVal(25)"> 25% </b-button>
							<b-button pill size="sm" variant="outline-primary" class="pctPills dwtButton" @click="updateSliderVal(50)"> 50% </b-button>
							<b-button pill size="sm" variant="outline-primary" class="pctPills dwtButton" @click="updateSliderVal(75)"> 75% </b-button>
							<b-button pill size="sm" variant="outline-primary" class="pctPills dwtButton" @click="updateSliderVal(100)"> 100% </b-button>
							<br />
							<b-button size="sm" class="sliderButtons dwtButton redButton" @click="cancelSliderWindow">Cancel</b-button>
							<b-button size="sm" class="sliderButtons dwtButton greenButton" @click="doSliderAction">Submit</b-button>
						</div>
						<div class="functionButtons" ref="funcButtons"><b-button @click="depositStaking" :disabled="!isConnected" size="sm" class="dwtButton">Deposit</b-button> <b-button @click="withdrawStaking" :disabled="!isConnected" size="sm" class="dwtButton">Withdraw</b-button></div>
						<div class="infoSection"><span class="userDataLabel">DWT Earned:</span> <span class="userDataInfo">{{formatNumber(userDWT)}}</span></div>
						<div class="priceSection"><span class="priceInfo">(${{formatNumber(earnedPrice)}})</span></div>
						<div class="functionButtons"><b-button @click="compoundDWT" :disabled="!isConnected" size="sm" class="dwtButton">Compound</b-button> <b-button @click="harvestDWT" :disabled="!isConnected" size="sm" class="dwtButton">Harvest</b-button></div>
					</b-card>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
/* eslint-disable */
import LoadSpinner from 'vue-spinner/src/GridLoader.vue';
import axios from 'axios';

/* wallet connect config */
import { createWeb3Modal, defaultWagmiConfig, useWeb3ModalTheme } from '@web3modal/wagmi/vue'
import { writeContract, watchAccount, waitForTransaction } from '@wagmi/core'
import { bsc } from 'viem/chains'

const projectId = 'a058da63c32ddce8ba59f2fbc4ef444c'

const metadata = {
  name: 'DWT Staking',
  description: 'DiveWallet - Staking',
  url: 'https://stake.divewallet.net',
  icons: ['https://stake.divewallet.net/img/logo.0fbe71de.png']
}

const chains = [bsc]
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })

createWeb3Modal({
	wagmiConfig,
	projectId,
	chains,
	enableAnalytics: true // Optional - defaults to your Cloud configuration
})

const { setThemeMode, setThemeVariables, themeMode, themeVariables } = useWeb3ModalTheme()

setThemeMode('light')

/* basic web3 */
import Web3 from "web3";
import { tokenABI } from './tokenABI.js';
import { stakingABI } from './stakingABI.js';
const w3Provider = new Web3('https://bsc-dataseed.binance.org');

const dwtAddr = "0x988c49d4a1515EE341b3540EC70a1F7A167b3759"
const dwtContract = new w3Provider.eth.Contract(tokenABI, dwtAddr)

const nftAddr = "0xc3E9335e658579d120fF9f632Ead3D70051d569e"
const nftContract = new w3Provider.eth.Contract(tokenABI, nftAddr)

const StakingAddr = "0xE4377dc88E9Db6f8e295F85C3b1BE3da25Fa0f16"
const stakingContract = new w3Provider.eth.Contract(stakingABI, StakingAddr)

export default {
	name: 'dwtStaking',
	components: {
		LoadSpinner
	},
	data() {
		return {
			initialLoad: true,
			dwtPrice: 0,
			totalStakedPrice: 0,
			walletPrice: 0,
			stakedPrice: 0,
			unstakedPrice: 0,
			earnedPrice: 0,

			loadingColor: "#680aff",
			loadingHeight: "50px",
			loadingWidth: "5px",
			sliderVal: 0,
			sliderTokens: 0,
			sliderAction: "",

			txAlertTime: 3,
			txAlertStatus: 0,
			txAlertVariant: "success",
			txAlertMessage: "Generic Message Box",

			userWalletDWT: 0,
			userWalletDWTFull: 0,
			userWallet: "",
			userStaked: 0,
			userStakedFull: 0,
			userUnstaked: 0,
			userUnstakedFull: 0,
			userDWT: 0,
			userNFT: 0,

			stakingTotalTokens: "",
			stakingAPR: "",
			stakingAPRnft: "",
			connectionType: "",
			isConnected: false,
			isMetaMask: false
		}
	},

	mounted() {
		this.updateStakingInfo();
		setInterval(this.updateUserInfo, 10000);
		setInterval(this.updateStakingInfo, 60000);

		watchAccount((curAccount) => {
			if(curAccount.address) {
				this.userWallet = curAccount.address;
				this.settxAlertBox('success', 'Wallet Connected')
				this.updateUserInfo();
				this.isConnected = true;
				this.initialLoad = false;
			} else {
				this.clearUserInfo();
				if(!this.initialLoad) {
					this.settxAlertBox('success', 'Wallet Disconnected')
				}
				this.userWallet = null;
				this.isConnected = false;
				this.stopTransaction();
			}
		});
	},

	methods: {
		formatNumber(num,dec) {
			if(dec < 0) { dec = 2; }
			let newNum = Math.floor(num * 100) / 100;
			return Number(newNum).toLocaleString("en-US", { minimumFractionDigits: dec, maximumFractionDigits: dec });
		},

		settxAlertBox(variant, message) {
			this.txAlertVariant = variant;
			this.txAlertMessage = message;
			this.txAlertStatus = this.txAlertTime;
		},

		txCountChange(dismissCountDown) {
			this.dismissCountDown = dismissCountDown
		},

		async updateStakingInfo() {
			var spTS = await stakingContract.methods.totalStaked().call();
			this.stakingTotalTokens = spTS/(10 ** 18)
			var stAPR = await stakingContract.methods.normalAPR().call();
			this.stakingAPR = (stAPR)/(10 ** 18);
			var nAPR = await stakingContract.methods.nftAPR().call();
			this.stakingAPRnft = (nAPR)/(10 ** 18);
			await axios
				.get("https://pricingapi.totenmacher.com/api/tokenprice/?apiKey=6MG4VVCRREIK360W51TSK6OXPZK79NVUVOZO&chainID=56&tokenAddr=0x988c49d4a1515EE341b3540EC70a1F7A167b3759")
				.then((res) => {
					this.dwtPrice = res.data.token_price;
				})
			this.totalStakedPrice = this.stakingTotalTokens * this.dwtPrice
		},

		async updateUserInfo() {
			if(this.isConnected) {
				const userTS = await stakingContract.methods.userStaked(this.userWallet).call();
				this.userStakedFull = userTS
				this.userStaked = (userTS) / (10 ** 18)
				const userPR = await stakingContract.methods.viewUnpaidDividends(this.userWallet).call();
				this.userDWT = (userPR) / (10 ** 18)
				const userBL = await dwtContract.methods.balanceOf(this.userWallet).call();
				this.userWalletDWTFull = userBL
				this.userWalletDWT = (userBL) / (10 ** 18)
				const userUNS = await dwtContract.methods.getUserUnstaked(this.userWallet).call();
				this.userUnstakedFull = userUNS
				this.userUnstaked = userUNS / (10 ** 18)
				this.walletPrice = this.userWalletDWT * this.dwtPrice
				this.stakedPrice = this.userStaked * this.dwtPrice
				this.unstakedPrice = this.userUnstaked * this.dwtPrice
				this.earnedPrice = this.userDWT * this.dwtPrice
				this.userNFT = await nftContract.methods.balanceOf(this.userWallet).call();
			}
		},

		clearUserInfo() {
			this.userWallet = ""
			this.userStaked = 0
			this.userUnstaked = 0
			this.userDWT = 0
			this.userWalletDWT = 0
			this.walletPrice = 0
			this.stakedPrice = 0
			this.unstakedPrice = 0
			this.earnedPrice = 0
			this.userNFT = 0;
			this.isConnected = false
			this.connectionType = ""
		},

		startTransaction() {
			this.$refs.loadWindow.style.display = "inline";
			this.$refs.stakeDisplay.style.opacity = "0.35";
		},

		stopTransaction() {
			this.$refs.loadWindow.style.display = "none";
			this.$refs.stakeDisplay.style.opacity = "1";
		},

		updateSliderTokens() {
			if(this.sliderAction == "Deposit") {
				this.sliderTokens = (this.sliderVal / 100) * this.userUnstaked
			}
			else {
				this.sliderTokens = (this.sliderVal / 100) * this.userStaked
			}
		},

		updateSliderVal(newSliderVal) {
			this.sliderVal = newSliderVal;
			this.updateSliderTokens();
		},
	
		resetSliderWindow(rswAction) {
			this.$refs.funcButtons.style.display = "none"
			this.sliderAction = rswAction
			if(this.sliderAction == "Deposit") {
				this.$refs.sliderActionText.style.color = "#007d11"
			}
			else {
				this.$refs.sliderActionText.style.color = "#ff0000"
			}
			this.sliderVal = 0
			this.sliderTokens = 0
			this.$refs.slideWindow.style.display = "block"
		},
		
		cancelSliderWindow() {
			this.$refs.funcButtons.style.display = "block"
			this.$refs.slideWindow.style.display = "none"
		},

		depositStaking() {
			this.resetSliderWindow("Deposit")
		},
	
		withdrawStaking() {
			this.resetSliderWindow("Withdraw")
		},
	
		async doSliderAction() {
			if(this.sliderVal > 0) {
				if(this.sliderAction == "Deposit") {
					this.startTransaction();

					var depositAmount = 0
					if(this.sliderVal == 100) {
						depositAmount = await dwtContract.methods.getUserUnstaked(this.userWallet).call();
					}
					else {
						var tmpdepositAmount = ""+(this.userUnstaked * (this.sliderVal / 100))+"";
						depositAmount = w3Provider.utils.toWei(tmpdepositAmount, 'ether');
					}

					try {
						const { hash } = await writeContract({
							address: StakingAddr,
							abi: stakingABI,
							functionName: 'stakeTokens',
							args: [depositAmount]
						})
						const contributeTX = await waitForTransaction({ confirmations: 1, hash, })

						this.settxAlertBox('success', 'Deposit was successful');
					} catch(err) {
						this.settxAlertBox('danger', err.message)
						console.log(err)
					} finally {
						this.cancelSliderWindow();
						this.stopTransaction();
					}

					this.updateUserInfo();
					this.updateStakingInfo();
				}
				else {
					this.startTransaction();

					var withdrawAmount = 0
					if(this.sliderVal == 100) {
						withdrawAmount = await stakingContract.methods.userStaked(this.userWallet).call();
					}
					else {
						var tmpwathdrawAmount = ""+(this.userStaked * (this.sliderVal / 100))+"";
						withdrawAmount = w3Provider.utils.toWei(tmpwathdrawAmount, 'ether');
					}

					try {
						const { hash } = await writeContract({
							address: StakingAddr,
							abi: stakingABI,
							functionName: 'withdrawTokens',
							args: [withdrawAmount]
						})
						const withdrawTX = await waitForTransaction({ confirmations: 1, hash, })

						this.settxAlertBox('success', 'Withdraw was successful');
					} catch(err) {
						this.settxAlertBox('danger', err.message)
						console.log(err)
					} finally {
						this.cancelSliderWindow();
						this.stopTransaction();
					}

					this.updateUserInfo();
					this.updateStakingInfo();
				}
			}
			else {
				this.settxAlertBox('danger', "No tokens selected")
			}
		},

		//Compound DWT
		async compoundDWT() {
			this.startTransaction();

			try {
				const { hash } = await writeContract({
					address: StakingAddr,
					abi: stakingABI,
					functionName: 'compoundDividends',
					args: [this.userWallet]
				})
				const compoundTX = await waitForTransaction({ confirmations: 1, hash, })

				this.settxAlertBox('success', 'Compound was successful');
			} catch(err) {
				this.settxAlertBox('danger', err.message)
				console.log(err)
			} finally {
				this.stopTransaction();
			}

			this.updateUserInfo();
			this.updateStakingInfo();
		},

		// Harvest DWT
		async harvestDWT() {
			this.startTransaction();

			try {
				const { hash } = await writeContract({
					address: StakingAddr,
					abi: stakingABI,
					functionName: 'harvestDividends'
				})
				const compoundTX = await waitForTransaction({ confirmations: 1, hash, })

				this.settxAlertBox('success', 'Harvest was successful');
			} catch(err) {
				this.settxAlertBox('danger', err.message)
				console.log(err)
			} finally {
				this.stopTransaction();
			}

			this.updateUserInfo();
			this.updateStakingInfo();
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.txAlertBox {
	position: fixed;
	top: 0;
	text-align: center;
	width: 100%;
	z-index: 2;
	font-size: 30px;
}

.loadingWindow {
	display: none;
	position: fixed;
	z-index: 10;
	top: 50%;
	left: 50%;
}

.sliderWindow {
	display: none;
	border-radius: 20px;
	border: 2px solid #1B144F;
	padding: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.pctPills {
	margin-right: 2px;
	margin-left: 2px;
	width: 55px;
}

.sliderButtons {
	margin: 5px;
	padding: 5px;
	text-align: right;
}

.stakingDisplay {
	width: 35%;
}

@media screen and (max-width: 1600px) {
	.stakingDisplay {
		width: 50%;
	}
}

@media screen and (max-width: 900px) {
	.stakingDisplay {
		width: 75%;
	}
}

@media screen and (max-width: 600px) {
	.stakingDisplay {
		width: 100%;
	}
}

.cardHeader {
	font-family: Montserrat, sans-serif;
	font-weight: bold;
	color: #000 !important;
	font-size: 20px;
}

.infoSection {
	padding-top: 13px;
}

.userDataLabel {
	font-weight: bold;
}

.userDataInfo {
	float: right;
}

.priceSection {
	padding-bottom: 13px;
}

.priceInfo {
	float: right;
	font-size: 13px;
	font-style: italic;
}

.logo-bold {
	font-family: 'Poppins', sans-serif;
}

.v-data-table {
	background-color: #BDBFC5 !important;
}

.logo {
	padding-left: 30px;
	padding-top: 15px;
}

.img-link {
	padding-left: 30px;
	padding-top: 15px;
	width: 15%
}

.dwtBody {
    background-color: #fff;
    background-image: url("@/assets/background.png");
    background-repeat: repeat;
    background-size: cover;
    background-attachment: fixed;
	height: 100%;
}

.dwtCards {
	background: #f5f7fa;
    border-bottom: 1px solid #e4ebf3;
    border-radius: 50px;
	margin-top: 10px;
	margin-bottom: 15px;
	padding: 10px;
    box-shadow: 1px 1px 7px #000;
}

.dwtAlert {
	border-bottom: 1px solid #e4ebf3;
    border-radius: 50px;
    box-shadow: 1px 1px 7px #000;
	margin-top: 10px;
}

.dwtButton {
	background-color: #3960e6;
    border-radius: 15px;
    box-shadow: 1px 1px 3px #000;
	font-family: Montserrat, sans-serif;
	color: #fff;
}

.dwtHeader {
	font-family: Montserrat, sans-serif;
	color: #fff;
    margin-left: 10px;
    font-size: 22px;
	vertical-align: middle;
}

.dwtNav {
	backdrop-filter: blur(5px);
    outline-offset: 0px;
    background-color: rgba(57, 96, 230, .3);
    border-radius: 20px;
    outline: 3px #1a1b1f;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
	margin-bottom: 30px;
    top: 0;
}

.redButton {
	background-color: darkred;
}

.greenButton {
	background-color: darkgreen;
}

.arrow {
  border: solid darkblue;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 15px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
</style>